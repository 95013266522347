import { IBaseRequest, IBaseSortable } from '@/api/types';
import { Country } from '@/entities';
import { DirectoryItems } from '@/entities/DirectoryItems';

interface IGetDirectoryItemsCountriesProps
  extends IBaseRequest,
    IBaseSortable {}

export async function getDirectoryItemsCountries({
  api,
  orderBy,
  orderDirection,
}: IGetDirectoryItemsCountriesProps): Promise<Country[]> {
  const response = await api.get<Country[]>('directory_items/country', {
    params: {
      orderBy,
      orderDirection,
    },
  });

  return response.data;
}
