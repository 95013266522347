import { IBaseRequest } from '@/api/types';
import { SupportRequest } from '@/entities/SupportRequest';

interface IGetSupportRequestsProps extends IBaseRequest {
  clientId: string;
}

export async function getSupportRequests({
  api,
  clientId,
}: IGetSupportRequestsProps): Promise<SupportRequest[]> {
  const response = await api.get<SupportRequest[]>(
    `clients/${clientId}/support_requests`,
  );

  return response.data;
}
