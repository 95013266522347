import { ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { config } from '@/config';
import ErrorFallback from './fallback';

if (config.node.env === 'production' && config.sentry.dsn) {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.sentry.currentEnv,
    release: config.app.version,
    autoSessionTracking: true,
    tracesSampleRate: 1.0,

    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
  });
}

interface IErrorControllerProps {
  fallback?: (props: { eventId: string }) => React.ReactElement;
  children: ReactNode;
}

export function ErrorController({ fallback, children }: IErrorControllerProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ eventId }) => {
        return fallback ? (
          fallback({ eventId })
        ) : (
          <ErrorFallback eventId={eventId} />
        );
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
