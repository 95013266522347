import { useCallback, useState } from 'react';

interface IUseControlledOpened {
  defaultOpened?: boolean;
  controlledOpen?: boolean;
  onOpenChange?: (value: boolean) => void;
}

export function useControlledOpen({
  defaultOpened,
  controlledOpen,
  onOpenChange,
}: IUseControlledOpened) {
  const [openedState, setOpenedState] = useState<boolean>(
    Boolean(defaultOpened),
  );

  const controlled = controlledOpen !== undefined;
  const opened = controlled ? controlledOpen : openedState;

  const handleOpenChange = useCallback(
    (value: boolean) => {
      if (!controlled) {
        setOpenedState(value);
      }

      onOpenChange && onOpenChange(value);
    },
    [controlled, onOpenChange],
  );

  return {
    controlled,
    opened,
    handleOpenChange,
  };
}
