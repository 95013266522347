import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIdle } from 'react-use';
import dayjs from 'dayjs';

export function useDistributedIdle(ms: number) {
  const isIdle = useIdle(1000);
  const trackingInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const [lastActivityMarkAt, setLastActivityMarkAt] = useState(0);
  const [pulse, setPulse] = useState(0);

  const markActivity = useCallback(() => {
    setPulse((p) => p + 1);

    if (!isIdle) {
      const currentActivityMarkAt = dayjs().unix().toString();
      setLastActivityMarkAt(Number(currentActivityMarkAt));
      localStorage.setItem('st.la', currentActivityMarkAt);
    }
  }, [isIdle]);

  const syncActivity = useCallback(() => {
    setLastActivityMarkAt(Number(localStorage.getItem('st.la') ?? 0));
  }, []);

  useEffect(() => {
    window.addEventListener('storage', syncActivity, false);

    return () => {
      window.removeEventListener('storage', syncActivity, false);
    };
  }, [syncActivity]);

  useEffect(() => {
    trackingInterval.current = setInterval(() => {
      // Remember: in background chrome have timer's throttling
      markActivity();
    }, 1000);

    return () => {
      trackingInterval.current && clearInterval(trackingInterval.current);
    };
  }, [markActivity]);

  const isInactive = useMemo(() => {
    // Wait for counter before showing session expired
    if (!lastActivityMarkAt) {
      return false;
    }

    const delta = dayjs().unix() - Number(lastActivityMarkAt);

    return delta > ms / 1000;
  }, [lastActivityMarkAt, ms, pulse]);

  useEffect(() => {
    // Reset on every mount
    localStorage.removeItem('st.la');
  }, []);

  return {
    isIdle: isInactive,
  };
}
