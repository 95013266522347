import { Wrapper } from '@/ui/components/desktop/Icon/shared';
import { SVGContainer } from '@/ui/components/desktop/Icon/shared/SVGContainer';
import { IIconProps } from '@/ui/components/types/icon';

export function IconCheck(props: IIconProps) {
  return (
    <Wrapper {...props}>
      <SVGContainer gradient={props.gradient} viewBox="0 0 13 12">
        <path
          d="M5.08618 10C4.99051 10 4.8974 9.9796 4.80685 9.9388C4.7163 9.89799 4.62831 9.83092 4.54287 9.73758L1.84094 6.78642C1.69156 6.62321 1.61968 6.42227 1.62531 6.18361C1.63042 5.94495 1.70767 5.74401 1.85705 5.5808C2.00593 5.41759 2.18984 5.33599 2.4088 5.33599C2.62725 5.33599 2.80579 5.41759 2.94443 5.5808L5.11841 7.95598L10.3458 2.24481C10.4844 2.0816 10.6604 2 10.8737 2C11.0871 2 11.2682 2.0816 11.417 2.24481C11.5557 2.40746 11.625 2.60253 11.625 2.83001C11.625 3.05694 11.5557 3.25201 11.417 3.41522L5.63025 9.73758C5.54481 9.83092 5.45682 9.89799 5.36627 9.9388C5.27572 9.9796 5.18236 10 5.08618 10Z"
          fill="currentColor"
        />
      </SVGContainer>
    </Wrapper>
  );
}
