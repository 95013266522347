import { IAgreementSign } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAgreementSignsProps extends IBaseRequest {
  clientId: string;
  state?: string;
  latest?: boolean;
  forCurrentTariff?: boolean;
}

export async function getAgreementSigns({
  api,
  clientId,
  state,
  forCurrentTariff,
  latest,
}: IGetAgreementSignsProps) {
  const response = await api.get<IAgreementSign[]>(
    `clients/${clientId}/agreement_signs`,
    {
      params: {
        state,
        forCurrentTariff,
        latest,
      },
    },
  );

  return response.data;
}
