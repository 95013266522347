import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import { ITextProps, TextKind } from '@/ui/components/mobile/Text/types.ts';

export const STYLES_BY_KIND: Record<TextKind, SerializedStyles> = {
  [TextKind.TITLE_H0]: css`
    font-size: 46px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0.8;
  `,
  [TextKind.TITLE_H1]: css`
    font-size: 32px;
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: initial;
  `,
  [TextKind.TITLE_H2]: css`
    font-size: 28px;
    font-weight: 500;
    line-height: 1.285;
    letter-spacing: initial;
  `,
  [TextKind.TITLE_H3]: css`
    font-size: 24px;
    font-weight: 500;
    line-height: 1.166;
    letter-spacing: 1px;
  `,
  [TextKind.TITLE_H4]: css`
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: 0.4px;
  `,
  [TextKind.AMOUNT_BOLD]: css`
    font-size: 22px;
    font-weight: 600;
    line-height: 1.37;
    letter-spacing: 0.945px;
  `,
  [TextKind.AMOUNT_DEFAULT]: css`
    font-size: 22px;
    font-weight: 400;
    line-height: 1.37;
    letter-spacing: 0.95px;
  `,
  [TextKind.BODY_MEDIUM]: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: initial;
  `,
  [TextKind.BODY_REGULAR]: css`
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: initial;
  `,
  [TextKind.BODY_SMALL]: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: initial;
  `,
  [TextKind.BODY_SMALL_SEMIBOLD]: css`
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: initial;
  `,
  [TextKind.CAPTION_REGULAR]: css`
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: initial;
  `,
  [TextKind.CAPTION_MEDIUM]: css`
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: initial;
  `,
  [TextKind.CAPTION_AVATAR]: css`
    font-size: 18px;
    font-weight: 600;
    line-height: 0.9;
    letter-spacing: 0.5px;
  `,
  [TextKind.CAPTION_SMALL_SEMIBOLD]: css`
    font-size: 10px;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: initial;
  `,
  [TextKind.CAPTION_SMALL]: css`
    font-size: 10px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: initial;
  `,
};

export const StyledText = styled.p<ITextProps>(
  ({
    kind = TextKind.BODY_REGULAR,
    color = 'var(--color-label-primary)',
    lineThrough,
    ellipsis,
  }) => css`
    margin: 0;
    padding: 0;
    word-break: break-word;

    font-family: var(--font-family);
    ${STYLES_BY_KIND[kind]};
    color: ${color};

    ${lineThrough &&
    css`
      text-decoration: line-through;
    `}

    ${ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
  `,
);
