import { IBaseRequest, IBaseSortable } from '@/api/types';
import { Salutation } from '@/entities';

interface IGetDirectoryItemsSalutationsProps
  extends IBaseRequest,
    IBaseSortable {}

export async function getDirectoryItemsSalutations({
  api,
}: IGetDirectoryItemsSalutationsProps): Promise<Salutation[]> {
  const response = await api.get<Salutation[]>('directory_items/salutation');

  return response.data;
}
