import { IContactDetail, IPaymentMethodValidateResult } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface IddPaymentMethodProps extends IBaseRequest {
  clientId?: string;
  contactId: string;
  dto: {
    id?: string;
    paymentKind: string;
    details: IContactDetail[];
  };
}

export async function addPaymentMethod({
  api,
  clientId,
  contactId,
  dto,
}: IddPaymentMethodProps): Promise<
  IBaseResponse<IPaymentMethodValidateResult>
> {
  try {
    const response = await api.post<IPaymentMethodValidateResult>(
      `clients/${clientId}/contacts/${contactId}/payment_methods`,
      dto,
    );

    return createSuccessResponse({
      result: response.data,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
