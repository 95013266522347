import { AuthMethod, IContact } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface IConfirmPaymentMethodsProps extends IBaseRequest {
  clientId?: string;
  contactId?: string;
  contactPaymentMethodId?: string;
  dto: {
    verificationCode: string;
    channel?: AuthMethod;
  };
}

export async function confirmPaymentMethod({
  api,
  clientId,
  contactId,
  contactPaymentMethodId,
  dto,
}: IConfirmPaymentMethodsProps): Promise<IBaseResponse<IContact>> {
  try {
    const response = await api.patch<IContact>(
      `/clients/${clientId}/contacts/${contactId}/payment_methods/${contactPaymentMethodId}/verification_source/confirm`,
      dto,
    );

    return createSuccessResponse({
      result: response.data,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
