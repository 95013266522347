import styled from '@emotion/styled';
import { BasePlacement } from '@popperjs/core';
import { CSSProperties } from 'react';
import { motion } from 'framer-motion';

const stylesByPlace: Record<BasePlacement, CSSProperties> = {
  top: {},
  bottom: { transform: 'rotate(180deg)' },
  left: { transform: 'rotate(-90deg)' },
  right: { transform: 'rotate(90deg)' },
};

export function getStylesByPlace(place: BasePlacement) {
  return stylesByPlace[place];
}

export const PopperContainer = styled.div`
  z-index: 5;
`;

export const StyledTooltip = styled(motion.div)`
  --background-color: var(--color-bg-two);

  max-width: 260px;
  padding: 12px;
  border-radius: 10px;
  background: var(--background-color);
  box-shadow: 0px 4px 8px 0px rgba(10, 12, 27, 0.08);
`;

export const ArrowWrapper = styled.div`
  &[data-hide] {
    visibility: hidden;
  }
`;
