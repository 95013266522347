import { IBaseRequest } from '@/api/types';

interface IGetBankNameProps extends IBaseRequest {
  dto: {
    accountNo: string;
    countryId: string;
    routingNo: string;
  };
}

interface IGetBankNameResponse {
  data: Record<string, string>;
  success: boolean;
}

export async function getBankName({
  api,
  dto,
}: IGetBankNameProps): Promise<IGetBankNameResponse> {
  const response = await api.post<IGetBankNameResponse>(
    `payment_details_lookup/bank_name`,
    dto,
  );

  return response.data;
}
