import { IContactDetail, IPaymentMethodValidateResult } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface IUpdatePaymentMethodProps extends IBaseRequest {
  clientId?: string;
  contactId: string;
  id: string;
  dto: {
    details: IContactDetail[];
  };
}

export async function updatePaymentMethod({
  api,
  clientId,
  contactId,
  id,
  dto,
}: IUpdatePaymentMethodProps): Promise<
  IBaseResponse<IPaymentMethodValidateResult>
> {
  try {
    const response = await api.patch<IPaymentMethodValidateResult>(
      `clients/${clientId}/contacts/${contactId}/payment_methods/${id}`,
      dto,
    );

    return createSuccessResponse({
      result: response.data,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
