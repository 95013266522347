import { AidFlow } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';
import { SupportRequest } from '@/entities/SupportRequest';

interface ICreateRequestLimitsUpdateProps extends IBaseRequest {
  clientId: string;
}

interface ICreateRequestLimitsUpdateResponse extends SupportRequest {}

export async function createRequestLimitsUpdate({
  api,
  clientId,
}: ICreateRequestLimitsUpdateProps): Promise<
  IBaseResponse<ICreateRequestLimitsUpdateResponse>
> {
  try {
    const response = await api.post<
      IBaseResponse<ICreateRequestLimitsUpdateResponse>
    >(`clients/${clientId}/request_limits_update`);

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
