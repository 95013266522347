import styled from '@emotion/styled';
import { Image } from '@/ui/components/mobile/Image';
import { Text } from '@/ui/components/mobile/Text';
import { Button } from '@/ui/components/mobile/Button';

export const Root = styled.div`
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
  background-color: var(--color-bg-one);
  padding: 16px;
`;

export const Wrapper = styled.div`
  --image-size: 312px;

  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: 'content' 'image';
  grid-template-rows: auto auto;
  grid-row-gap: 58px;
  align-items: center;
  justify-items: center;
  padding: 30px 0;
`;

export const ContentWrapper = styled.div`
  grid-area: content;
`;

export const Title = styled(Text)`
  text-align: center;
  color: var(--color-label-primary);
`;

export const SubTitle = styled(Text)`
  margin-top: 40px;
  color: var(--color-label-primary);
`;

export const Description = styled(Text)`
  margin-top: 24px;
  color: var(--color-label-primary);
`;

export const DownloadReportDescription = styled(Text)`
  margin-top: 12px;
  color: var(--color-label-primary);
`;

export const ButtonsWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 16px;
  display: grid;
  grid-template-areas: 'primary' 'chat';
  grid-gap: 20px;
  width: 100%;
`;

export const PrimaryButton = styled(Button)`
  grid-area: primary;
`;

export const ChatButton = styled(Button)`
  grid-area: chat;
`;

export const DownloadReportContainer = styled.div`
  margin-top: 32px;
`;

export const StyledImage = styled(Image)`
  width: var(--image-size);
  height: var(--image-size);
  grid-area: image;
`;

export const TraceIdWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  grid-column-gap: 6px;
  margin-top: 20px;
`;
