import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { TextOrChildren } from '@/ui/components/desktop/Text';
import { CopyIconPosition } from '@/ui/components/desktop/TextWithCopy/types.ts';
import { StyledIconCopy } from '@/ui/components/desktop/TextWithCopy/partials';
import { css } from '@emotion/react';

interface IWrapperStyleProps {
  canDoCopy?: boolean;
  copyIconPosition: CopyIconPosition;
  alwaysShowCopyIcon: boolean;
  color?: string;
}

export const Wrapper = styled.div<IWrapperStyleProps>(
  ({ copyIconPosition, canDoCopy, color, alwaysShowCopyIcon }) => css`
    --copy-icon-color: var(--color-label-tertiary);

    position: relative;
    display: grid;
    grid-template-columns: ${copyIconPosition === CopyIconPosition.LEFT
      ? '20px auto'
      : 'auto 20px'};
    grid-template-areas: ${copyIconPosition === CopyIconPosition.LEFT
      ? "'copyContent value'"
      : "'value copyContent'"};
    grid-column-gap: 4px;
    align-items: center;
    justify-content: left;
    min-height: 20px;
    cursor: ${canDoCopy ? 'pointer' : 'initial'};
    color: ${color};

    ${alwaysShowCopyIcon &&
    css`
      ${StyledIconCopy} {
        opacity: 1;
        pointer-events: initial;
      }
    `}

    &:hover {
      ${StyledIconCopy} {
        opacity: 1;
        pointer-events: initial;
      }
    }
  `,
);

export const ValueText = styled(TextOrChildren)`
  grid-area: value;
  color: inherit;
`;

export const StyledMotionDiv = styled(motion.div)`
  display: flex;
`;
