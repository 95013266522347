import { ITextProps } from '@/ui/components/mobile/Text/types.ts';

export enum CopyIconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface ITextWithCopyProps extends ITextProps {
  textToCopy?: string;
  withCopy?: boolean;
  copyIconPosition?: CopyIconPosition;
  alwaysShowCopyIcon?: boolean;
  iconSize?: string;
}
