import { ClientDraft, ClientDraftKind } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IUpdateClientDraftProps extends IBaseRequest {
  dto: {
    tariffId?: string;
    countryId?: string;
    displayName?: string;
    kind?: ClientDraftKind;
    primaryCurrencyId?: string;
    entityName?: string;
    firstName?: string;
    lastName?: string;
  };
  clientDraftId: string;
}

export async function updateClientDraft({
  api,
  dto,
  clientDraftId,
}: IUpdateClientDraftProps): Promise<IBaseResponse<ClientDraft>> {
  try {
    const response = await api.put<IBaseResponse<ClientDraft>>(
      `client_drafts/${clientDraftId}`,
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
