import { IBaseRequest } from '@/api/types';
import { Account } from '@/entities';
import { insertIfObj } from '@/functions/utils/insertIf.ts';

interface IGetPaymentMethodAccountsProps extends IBaseRequest {
  clientId: string;
  paymentMethodId: string;
  senderCurrencyId: string;
  receiverCurrencyId?: string;
  senderBankEntityId?: string;
  direction: string;
}

export async function getPaymentMethodAccounts({
  api,
  clientId,
  paymentMethodId,
  senderCurrencyId,
  receiverCurrencyId,
  senderBankEntityId,
  direction,
}: IGetPaymentMethodAccountsProps) {
  const response = await api.get<Account[]>(
    `clients/${clientId}/payment_methods/${paymentMethodId}/accounts`,
    {
      params: {
        senderCurrencyId,
        direction,
        ...insertIfObj(Boolean(receiverCurrencyId), {
          receiverCurrencyId,
        }),
        ...insertIfObj(Boolean(senderBankEntityId), {
          senderBankEntityId,
        }),
      },
    },
  );

  return response.data;
}
