import { IAccountsBankLetterAvailability } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAccountConfirmationLetterAvailabilityProps extends IBaseRequest {
  clientId: string;
}

export async function getAccountConfirmationLetterAvailability({
  api,
  clientId,
}: IGetAccountConfirmationLetterAvailabilityProps): Promise<IAccountsBankLetterAvailability> {
  const response = await api.get<IAccountsBankLetterAvailability>(
    `clients/${clientId}/client_accounts/account_confirmation_letter/availability`,
  );

  return response.data;
}
