import { IBaseRequest } from '@/api/types';
import { IDynamicPaymentDetails } from '@/entities';

interface IGetClientAccountInstructionsProps extends IBaseRequest {
  clientId: string;
  id: string;
}

export async function getClientAccountInstructions({
  api,
  clientId,
  id,
}: IGetClientAccountInstructionsProps): Promise<IDynamicPaymentDetails[]> {
  const response = await api.get<IDynamicPaymentDetails[]>(
    `clients/${clientId}/client_accounts/${id}/instructions`,
  );

  return response.data;
}
