import { IBaseRequest } from '@/api';
import { AccountMaxTransactionAmount } from '@/entities';

interface IGetMaxTransactionAmountProps extends IBaseRequest {
  clientId: string;
  accountId: string;
  paymentMethod: string;
}

export async function getMaxTransactionAmountByClient({
  api,
  clientId,
  accountId,
  paymentMethod,
}: IGetMaxTransactionAmountProps): Promise<AccountMaxTransactionAmount> {
  const response = await api.get<AccountMaxTransactionAmount>(
    `clients/${clientId}/accounts/${accountId}/max_transaction_amount`,
    { params: { paymentMethod } },
  );

  return response.data;
}
