import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Text } from '@/ui/components/mobile/Text';
import { CopyIconPosition } from '@/ui/components/mobile/TextWithCopy/types.ts';
import { StyledIconCopy } from '@/ui/components/mobile/TextWithCopy/partials';
import { css } from '@emotion/react';

interface IWrapperStyleProps {
  canDoCopy?: boolean;
  alwaysShowCopyIcon: boolean;
  copyIconPosition: CopyIconPosition;
  color?: string;
}

export const Wrapper = styled.div<IWrapperStyleProps>(
  ({ copyIconPosition, canDoCopy, alwaysShowCopyIcon, color }) => css`
    position: relative;
    display: grid;
    grid-template-columns: ${copyIconPosition === CopyIconPosition.LEFT
      ? '20px auto'
      : 'auto'};
    grid-template-areas: ${copyIconPosition === CopyIconPosition.LEFT
      ? "'copyContent value'"
      : "'value copyContent'"};
    grid-column-gap: 12px;
    align-items: center;
    justify-content: left;
    min-height: 20px;
    cursor: ${canDoCopy ? 'pointer' : 'initial'};
    color: ${color};
    -webkit-tap-highlight-color: transparent;

    ${alwaysShowCopyIcon &&
    css`
      ${StyledIconCopy} {
        opacity: 1;
        pointer-events: initial;
      }
    `}

    &:hover {
      ${StyledIconCopy} {
        opacity: 1;
        pointer-events: initial;
      }
    }
  `,
);

export const ValueText = styled(Text)`
  grid-area: value;
  color: inherit;
`;

export const StyledMotionDiv = styled(motion.div)`
  display: flex;
`;
