import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface IValidateCountryProps extends IBaseRequest {
  dto: {
    countryId: string;
    countryKind: string;
    clientKind: string;
  };
}

interface IValidateCountryResponse {
  success: boolean;
}

export async function validateCountry({
  api,
  dto,
}: IValidateCountryProps): Promise<IBaseResponse<IValidateCountryResponse>> {
  try {
    const response = await api.post<IValidateCountryResponse>(
      `/countries/validate`,
      dto,
    );

    return createSuccessResponse({
      result: response.data,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
