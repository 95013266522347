import { StyledArrow } from './index.styles';

export function Arrow() {
  return (
    <StyledArrow viewBox="0 0 12 12" fill="none">
      <path
        d="M4.21114 10.4223C4.94819 11.8964 7.0518 11.8964 7.78885 10.4223L11.5528 2.89443C12.2177 1.56463 11.2507 0 9.76393 0L2.23607 0C0.749304 0 -0.217688 1.56462 0.447213 2.89443L4.21114 10.4223Z"
        fill="var(--background-color)"
      />
    </StyledArrow>
  );
}
