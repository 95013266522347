import React, { useCallback, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { AnimatePresence } from 'framer-motion';
import { Modifier, ModifierArguments, BasePlacement } from '@popperjs/core';
import {
  ArrowWrapper,
  PopperContainer,
  StyledTooltip,
  getStylesByPlace,
} from './index.styles';
import { VARIANTS } from './animation';
import { Arrow } from './partials/Arrow';
import { ITooltipProps } from '../../types/tooltip';
import { useControlledOpen } from '@/ui/hooks/useControlledOpen';

export function Tooltip({
  opened: controlledOpen,
  defaultOpened,
  placement = 'top',
  content,
  collisionBoundary,
  offset = [0, 12],
  portalContainer = document.body,
  withArrow = true,
  children,
  className,
  onOpenChange,
  zIndex,
  tooltipStyles,
  needToShowTooltip = true,
  mode = 'default',
}: ITooltipProps) {
  const { opened, handleOpenChange } = useControlledOpen({
    defaultOpened,
    controlledOpen,
    onOpenChange,
  });

  const [refElement, setRefElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  const timerId = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleOpen = useCallback(() => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }

    handleOpenChange(true);
  }, [handleOpenChange]);

  const handleClose = useCallback(() => {
    timerId.current = setTimeout(() => handleOpenChange(false), 300);
  }, [handleOpenChange]);

  const events = useMemo(
    () => ({
      default: {
        onMouseEnter: handleOpen,
        onMouseLeave: handleClose,
      },
      pointer: {
        onPointerOver: handleOpen,
        onPointerOut: handleClose,
      },
    }),
    [handleClose, handleOpen],
  );

  const applyArrowStylesModifier: Modifier<string, object> = useMemo(
    () => ({
      name: 'applyArrowStyles',
      enabled: true,
      phase: 'afterWrite',
      fn({ state }: ModifierArguments<object>) {
        const {
          placement,
          elements: { arrow },
        } = state;

        const place = placement.split('-')[0] as BasePlacement;

        const staticSide = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right',
        }[place] as BasePlacement;

        const styles = getStylesByPlace(place);
        const appliedStyles = {
          [staticSide]: '-14px',
          ...styles,
          transform: [arrow?.style.transform, styles.transform]
            .filter((rule) => Boolean(rule))
            .join(' '),
        };

        if (arrow) {
          // Style property is readonly, for override styles use assign
          Object.assign(arrow.style, appliedStyles);
        }
      },
    }),
    [],
  );

  const applyArrowHide: Modifier<string, object> = useMemo(
    () => ({
      name: 'applyArrowHide',
      enabled: true,
      phase: 'write',
      fn({ state }: ModifierArguments<object>) {
        const { arrow } = state.elements;

        if (arrow) {
          if (state.modifiersData?.arrow?.centerOffset !== 0) {
            arrow.setAttribute('data-hide', '');
          } else {
            arrow.removeAttribute('data-hide');
          }
        }
      },
    }),
    [],
  );

  const { styles, attributes } = usePopper(refElement, popperElement, {
    strategy: 'absolute',
    placement,
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          boundary: collisionBoundary,
        },
      },
      {
        name: 'offset',
        enabled: Boolean(offset),
        options: {
          offset,
        },
      },
      { name: 'flip' },
      { name: 'arrow', options: { element: arrowElement, padding: 8 } },
      { ...applyArrowStylesModifier },
      { ...applyArrowHide },
    ],
  });

  const clonedChildren = useMemo(() => {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        ...children.props,
        ref: setRefElement,
        ...events[mode],
      });
    }
  }, [children, events, mode]);

  const renderedTooltip = useMemo(
    () => (
      <AnimatePresence>
        {opened && needToShowTooltip && (
          <PopperContainer
            ref={setPopperElement}
            style={{ ...styles.popper, zIndex }}
            {...attributes.popper}
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
          >
            <StyledTooltip
              variants={VARIANTS}
              initial="hide"
              animate={opened ? 'show' : 'hide'}
              exit="hide"
              transition={{ ease: 'easeInOut', duration: 0.3 }}
              className={className}
              style={{ ...tooltipStyles }}
            >
              {withArrow && (
                <ArrowWrapper ref={setArrowElement} style={styles.arrow}>
                  <Arrow />
                </ArrowWrapper>
              )}
              {content}
            </StyledTooltip>
          </PopperContainer>
        )}
      </AnimatePresence>
    ),
    [
      needToShowTooltip,
      attributes.popper,
      className,
      content,
      handleClose,
      handleOpen,
      opened,
      styles.arrow,
      styles.popper,
      tooltipStyles,
      withArrow,
      zIndex,
    ],
  );

  return (
    <>
      {clonedChildren}
      {ReactDOM.createPortal(renderedTooltip, portalContainer)}
    </>
  );
}
