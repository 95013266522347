import { useApi } from '@/api';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { config } from '@/config';
import { useAuth } from '@/auth/useAuth.ts';
import { useDebounce } from '@/functions/utils/useDebounce.ts';

export function useAuthChecking() {
  const { api, client } = useApi();
  const {
    authTokenLogic: { handleSetTokens },
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const redirectId = useMemo(
    () => new URLSearchParams(location.search).get('redirect_id') ?? '',
    [location.search],
  );

  const debouncedRedirectId = useDebounce(redirectId, 1000);

  const handleAuthRedirect = useCallback(async () => {
    if (!redirectId) {
      return;
    }

    const response = await api.auth.getSessionRedirect({
      api: client,
      id: redirectId,
    });

    if (response.success) {
      handleSetTokens({
        accessToken: response?.extra?.token ?? '',
        refreshToken: response.extra?.refreshToken ?? '',
      });

      localStorage.setItem(
        config.security.session.storageKeys.lastClient,
        response.extra?.model.subjectId ?? '',
      );
    }
    navigate('/');
  }, [api.auth, client, handleSetTokens, navigate, redirectId]);

  useEffect(() => {
    handleAuthRedirect();
  }, []);

  return {
    redirectId: redirectId || debouncedRedirectId,
  };
}
