import { IContactPaymentMethod } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetPaymentMethodsProps extends IBaseRequest {
  clientId: string;
  contactId: string;
  id: string;
}

export async function getContactPaymentMethod({
  api,
  clientId,
  contactId,
  id,
}: IGetPaymentMethodsProps): Promise<IContactPaymentMethod> {
  const response = await api.get<IContactPaymentMethod>(
    `clients/${clientId}/contacts/${contactId}/payment_methods/${id}`,
  );

  return response.data;
}
