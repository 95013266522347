import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface IValidateTariffAnswersProps extends IBaseRequest {
  dto: {
    ids?: Array<string | undefined>;
  };
}

interface IValidateTariffAnswersResponse {
  success: boolean;
}

export async function validateTariffAnswers({
  api,
  dto,
}: IValidateTariffAnswersProps): Promise<
  IBaseResponse<IValidateTariffAnswersResponse>
> {
  try {
    const response = await api.post<IValidateTariffAnswersResponse>(
      `/tariff_questions/validate_answers`,
      dto,
    );

    return createSuccessResponse({
      result: response.data,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
