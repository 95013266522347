import {
  ButtonText,
  Wrapper,
} from '@/ui/components/desktop/Button/index.styles.ts';
import {
  ButtonKind,
  ButtonStyleGuide,
  IButtonProps,
} from '@/ui/components/desktop/Button/types.ts';
import { ParentBackground } from '@/ui/components/types/shared.ts';
import { forwardRef } from 'react';
import { disabledClickPrevent } from '@/functions/utils/disabledClickPrevent';
import { TextKind } from '@/ui/components/desktop/Text/types.ts';
import { IconLoader } from '@/ui/components/desktop/Icon/variations/Loader';
import { Tooltip } from '../Tooltip';

const TEXT_KIND_BY_BUTTON_KIND = {
  [ButtonKind.SMALL]: TextKind.FOOTNOTE_DEFAULT,
  [ButtonKind.MEDIUM]: TextKind.BODY_DEFAULT,
  [ButtonKind.LARGE]: TextKind.BODY_BOLD,
};

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      styleGuide = ButtonStyleGuide.BRAND,
      kind = ButtonKind.MEDIUM,
      parentBackground = ParentBackground.TWO,
      icon,
      fullWidth = false,
      children,
      onClick,
      disabled,
      loading = false,
      transparent = false,
      hint,
      ...props
    },
    ref,
  ) => {
    if (hint) {
      return (
        <Tooltip {...hint}>
          <div>
            <Wrapper
              ref={ref}
              hasChildren={Boolean(children)}
              disabled={disabled || loading}
              onClick={disabledClickPrevent(disabled || loading, onClick)}
              styleGuide={styleGuide}
              kind={kind}
              hasIcon={Boolean(icon) || loading}
              parentBackground={parentBackground}
              fullWidth={fullWidth}
              transparent={transparent}
              {...props}
            >
              {loading ? <IconLoader /> : icon}
              <ButtonText kind={TEXT_KIND_BY_BUTTON_KIND[kind]}>
                {children}
              </ButtonText>
            </Wrapper>
          </div>
        </Tooltip>
      );
    }

    return (
      <Wrapper
        ref={ref}
        hasChildren={Boolean(children)}
        disabled={disabled || loading}
        onClick={disabledClickPrevent(disabled || loading, onClick)}
        styleGuide={styleGuide}
        kind={kind}
        hasIcon={Boolean(icon) || loading}
        parentBackground={parentBackground}
        fullWidth={fullWidth}
        transparent={transparent}
        {...props}
      >
        {loading ? <IconLoader /> : icon}
        <ButtonText kind={TEXT_KIND_BY_BUTTON_KIND[kind]}>
          {children}
        </ButtonText>
      </Wrapper>
    );
  },
);
