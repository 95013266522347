import { IBaseRequest } from '@/api/types';
import { Account } from '@/entities';

interface IGetClientClientAccountsProps extends IBaseRequest {
  clientId: string;
}

export async function getClientClientAccounts({
  api,
  clientId,
}: IGetClientClientAccountsProps): Promise<Account[]> {
  const response = await api.get<Account[]>(
    `clients/${clientId}/client_accounts`,
  );

  return response.data;
}
