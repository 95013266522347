const TRANSITION = {
  icon: {
    type: 'spring',
    velocity: 4,
  },
  contentBackground: {
    show: {
      duration: 1,
      ease: [0.16, 1, 0.3, 1],
    },
    hide: {
      duration: 0.3,
      delay: 0.1,
    },
  },
};

export const VARIANTS = {
  copyIcon: {
    show: {
      opacity: 1,
      scale: 1,
      transition: TRANSITION.icon,
    },
    hide: {
      opacity: 0,
      scale: 0.5,
      transition: TRANSITION.icon,
    },
  },
  contentBackground: {
    initial: {
      opacity: 0,
      scale: 1.2,
      transition: TRANSITION.contentBackground.hide,
    },
    show: {
      opacity: 1,
      scale: 1,
      transition: TRANSITION.contentBackground.show,
    },
    hide: {
      opacity: 0,
      transition: TRANSITION.contentBackground.hide,
    },
  },
  content: {
    initial: {
      opacity: 0,
      scale: 1.2,
      transition: { ...TRANSITION.contentBackground.hide, delay: 0 },
    },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        ...TRANSITION.contentBackground.show,
        delay: 0.2,
        duration: 1.2,
      },
    },
    hide: {
      opacity: 0,
      transition: { ...TRANSITION.contentBackground.hide, delay: 0 },
    },
  },
};
