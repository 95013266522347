import { IAgreementSign } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAgreementsToSignProps extends IBaseRequest {
  clientDraftId: string;
}

export async function getAgreementsToSign({
  api,
  clientDraftId,
}: IGetAgreementsToSignProps): Promise<IAgreementSign[]> {
  const response = await api.get<IAgreementSign[]>(
    `client_drafts/${clientDraftId}/agreement_signs/to_be_signed`,
  );

  return response.data;
}
