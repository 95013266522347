import styled from '@emotion/styled';
import { Text } from '@/ui/components/mobile/Text';
import { motion } from 'framer-motion';
import { IconCopy } from '@/ui/components/mobile/Icon/variations/Copy';

export const CopyIconWrapper = styled.div`
  grid-area: copyContent;
`;

export const StyledIconCopy = styled(IconCopy)`
  --icon-color: var(--color-label-quaternary);

  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
`;

export const CopiedContentConstraint = styled.div`
  position: absolute;
  grid-area: value;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const CopiedContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const CopiedContentBackground = styled(motion.div)`
  position: absolute;
  top: -4px;
  left: -8px;
  width: 100%;
  height: 100%;
  padding: 4px 8px;
  box-sizing: content-box;
  z-index: -1;
  border-radius: 4px;
  background-color: var(--color-success-additional-on-bg-two);
`;

export const CopiedTextWrapper = styled(motion.div)`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
`;

export const CopiedText = styled(Text)`
  white-space: nowrap;
  color: var(--color-success-default);
`;
