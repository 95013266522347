import { Wrapper } from '@/ui/components/desktop/Icon/shared';
import { SVGContainer } from '@/ui/components/desktop/Icon/shared/SVGContainer';
import { IIconProps } from '@/ui/components/types/icon';

export function IconCopy(props: IIconProps) {
  return (
    <Wrapper {...props}>
      <SVGContainer gradient={props.gradient} viewBox="0 0 20 20">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.95 16.25H12.8C13.3229 16.25 13.75 16.6772 13.75 17.2C13.75 17.7229 13.3229 18.15 12.8 18.15H4.8C3.83716 18.15 3.05 17.3629 3.05 16.4V5.99999C3.05 5.47714 3.47716 5.04998 4 5.04998C4.52285 5.04998 4.95 5.47714 4.95 5.99999V16.25ZM16.95 3.59998V13.2C16.95 14.1628 16.1629 14.95 15.2 14.95H8.00001C7.03717 14.95 6.25001 14.1628 6.25001 13.2V3.59998C6.25001 2.63713 7.03717 1.84998 8.00001 1.84998H15.2C16.1629 1.84998 16.95 2.63713 16.95 3.59998ZM8.15001 13.05H15.05V3.74998H8.15001V13.05Z"
          fill="currentColor"
        />
      </SVGContainer>
    </Wrapper>
  );
}
