import { useCallback, useMemo, useState } from 'react';
import { useApi } from '@/api';
import { CacheKeys } from '@/api/keys';
import { useQuery } from '@tanstack/react-query';
import { useSelectedClient } from '@/providers/ClientSelectProvider/useSelectedClient.ts';

export function useSettings() {
  const [isUpdating, setUpdating] = useState(false);

  const { api, client, mutate } = useApi();
  const { clientDraft } = useSelectedClient();

  const { isLoading, data } = useQuery({
    queryKey: [CacheKeys.settings],
    queryFn: async () => api.user.getSettings({ api: client }),
  });

  const key = useMemo(() => {
    if (clientDraft) {
      return { save: clientDraft.id, get: clientDraft.id };
    }

    return { save: 'pre_draft', get: 'preDraft' };
  }, [clientDraft]);

  const handleSaveSettings = useCallback(
    async (values: Record<string, unknown>, cacheReset: boolean = true) => {
      try {
        setUpdating(true);

        await api.user.saveSettings({
          api: client,
          dto: {
            settings: values,
          },
        });

        if (cacheReset) {
          await mutate([CacheKeys.settings]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setUpdating(false);
      }
    },
    [api.user, client, mutate],
  );

  const handleSaveOnboardingProgress = useCallback(
    async (step: string, type: string, withoutCacheReset?: boolean) => {
      await handleSaveSettings(
        {
          ...data?.settings,
          onboarding: {
            ...data?.settings?.onboarding,
            [key.save]: {
              ...data?.settings?.onboarding?.[key.get],
              step,
              type,
            },
          },
        },
        withoutCacheReset,
      );
    },
    [handleSaveSettings, data?.settings, key.save, key.get],
  );

  const transferPreDraftMetaToDraft = useCallback(
    async (clientDraftId: string, step: string, type: string) => {
      await handleSaveSettings(
        {
          ...data?.settings,
          onboarding: {
            ...data?.settings?.onboarding,
            [clientDraftId]: {
              ...data?.settings?.onboarding?.preDraft,
              step,
              type,
            },
            pre_draft: {},
          },
        },
        true,
      );
    },
    [handleSaveSettings, data?.settings],
  );

  const onboardingProgress = useMemo(
    () => data?.settings?.onboarding?.[key.get],
    [data?.settings?.onboarding, key.get],
  );

  const onboardingStepFromSettings = useMemo(
    () => onboardingProgress?.step,
    [onboardingProgress],
  );

  const onboardingTypeFromSettings = useMemo(
    () => onboardingProgress?.type,
    [onboardingProgress],
  );

  return {
    settings: data?.settings ?? {},
    isLoading,
    isUpdating,
    setUpdating,
    handleSaveSettings,

    onboardingProgress,
    onboardingTypeFromSettings,
    onboardingStepFromSettings,
    handleSaveOnboardingProgress,
    transferPreDraftMetaToDraft,
  };
}
