import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCopyToClipboard } from 'react-use';
import { ValueText, Wrapper } from './index.styles';
import { CopiedContent } from '@/ui/components/desktop/TextWithCopy/partials';
import {
  CopyIconPosition,
  ITextWithCopyProps,
} from '@/ui/components/desktop/TextWithCopy/types.ts';
import { TextKind } from '../Text/types';

function getCopyValue(textToCopy?: string, value?: string | ReactNode) {
  if (textToCopy) {
    return textToCopy;
  }

  if (typeof value !== 'string') {
    return '';
  }

  return value;
}

export const TextWithCopy = forwardRef<
  HTMLParagraphElement,
  ITextWithCopyProps
>(
  (
    {
      textToCopy,
      withCopy = true,
      copyIconPosition = CopyIconPosition.RIGHT,
      className,
      color = 'var(--color-label-primary)',
      alwaysShowCopyIcon = false,
      kind = TextKind.BODY_DEFAULT,
      iconSize = '20px',
      ...props
    },
    ref,
  ) => {
    const [isCopied, setIsCopied] = useState(false);

    const [, copyToClipboard] = useCopyToClipboard();

    const copyValue = useMemo(
      () => getCopyValue(textToCopy, props.children),
      [textToCopy, props.children],
    );

    const canDoCopy = Boolean(copyValue) && withCopy && !isCopied;

    const onCopy = useCallback(
      (event: React.MouseEvent) => {
        if (canDoCopy) {
          event.stopPropagation();
          copyToClipboard(copyValue);
          setIsCopied(true);
        }
      },
      [canDoCopy, copyToClipboard, copyValue],
    );

    useEffect(() => {
      isCopied && setTimeout(() => setIsCopied(false), 2400);
    }, [isCopied]);

    return (
      <Wrapper
        data-test-id={props['data-test-id']}
        className={className}
        onClick={onCopy}
        canDoCopy={canDoCopy}
        copyIconPosition={copyIconPosition}
        alwaysShowCopyIcon={alwaysShowCopyIcon}
        color={color}
      >
        <ValueText
          {...props}
          data-test-id={`${props['data-test-id']}-text`}
          kind={kind}
          ref={ref}
        />
        <CopiedContent
          canDoCopy={canDoCopy}
          isCopied={isCopied}
          kind={kind}
          iconSize={iconSize}
          copyIconPosition={copyIconPosition}
        />
      </Wrapper>
    );
  },
);
