import { useState } from 'react';
import { usePopper } from 'react-popper';
import { AnimatePresence } from 'framer-motion';
import { IconCheck } from '@/ui/components/desktop/Icon/variations/Check';
import { StyledMotionDiv } from '../../index.styles';
import { VARIANTS } from './animation';
import {
  CopiedContentBackground,
  CopiedContentConstraint,
  CopiedContentWrapper,
  CopiedText,
  CopiedTextWrapper,
  CopyIconWrapper,
  StyledIconCopy,
} from './index.styles';
import { TextKind } from '@/ui/components/desktop/Text/types.ts';
import { CopyIconPosition } from '@/ui/components/desktop/TextWithCopy/types.ts';

interface ICopiedContentProps {
  canDoCopy: boolean;
  isCopied: boolean;
  kind: TextKind;
  iconSize: string;
  copyIconPosition: CopyIconPosition;
}

export function CopiedContent({
  canDoCopy,
  isCopied,
  kind,
  iconSize,
  copyIconPosition,
}: ICopiedContentProps) {
  const [constraintElement, setConstraintElement] =
    useState<HTMLElement | null>(null);
  const [copiedContentElement, setCopiedContentElement] =
    useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(
    constraintElement,
    copiedContentElement,
    {
      strategy: 'fixed',
      placement: copyIconPosition === CopyIconPosition.LEFT ? 'right' : 'left',
      modifiers: [
        {
          name: 'preventOverflow',
          enabled: false,
        },
        {
          name: 'flip',
          enabled: false,
        },
        {
          name: 'offset',
          options: {
            offset: [0, Number(copiedContentElement?.offsetWidth) * -1],
          },
        },
      ],
    },
  );

  return (
    <AnimatePresence>
      <CopyIconWrapper key="copy-icon-wrapper">
        {canDoCopy && (
          <StyledMotionDiv
            key="icon"
            variants={VARIANTS.copyIcon}
            initial="hide"
            animate="show"
            exit="hide"
          >
            <StyledIconCopy
              size={iconSize}
              color="var(--color-label-secondary)"
            />
          </StyledMotionDiv>
        )}
      </CopyIconWrapper>
      <CopiedContentConstraint ref={setConstraintElement} />
      {isCopied && (
        <CopiedContentWrapper
          ref={setCopiedContentElement}
          style={{
            ...styles.popper,
            minWidth: constraintElement?.offsetWidth,
            height: constraintElement?.offsetHeight,
          }}
          {...attributes.popper}
          key="tooltip"
        >
          <CopiedTextWrapper
            variants={VARIANTS.content}
            initial="initial"
            animate="show"
            exit="hide"
          >
            <IconCheck size={iconSize} color="var(--color-success-default)" />
            <CopiedText kind={kind}>Copied</CopiedText>
          </CopiedTextWrapper>
          <CopiedContentBackground
            variants={VARIANTS.contentBackground}
            initial="initial"
            animate="show"
            exit="hide"
            style={{
              minWidth: constraintElement?.offsetWidth,
              height: constraintElement?.offsetHeight,
            }}
          />
        </CopiedContentWrapper>
      )}
    </AnimatePresence>
  );
}

export { StyledIconCopy } from './index.styles.ts';
