import { useResolution } from '@/device/useResolution';
import { ErrorFallback as ErrorFallbackMobile } from './mobile';
import { ErrorFallback as ErrorFallbackDesktop } from './desktop';
import { IErrorFallbackProps } from './types';

export default function ErrorFallback(props: IErrorFallbackProps) {
  const { isMobile } = useResolution();

  if (isMobile) {
    return <ErrorFallbackMobile {...props} />;
  }

  return <ErrorFallbackDesktop {...props} />;
}
