import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
  MouseEvent,
} from 'react';
import { useCopyToClipboard } from 'react-use';
import { ValueText, Wrapper } from './index.styles';
import { CopiedContent } from '@/ui/components/mobile/TextWithCopy/partials';
import {
  CopyIconPosition,
  ITextWithCopyProps,
} from '@/ui/components/mobile/TextWithCopy/types.ts';
import { TextKind } from '../Text/types';

function getCopyValue(textToCopy?: string, value?: string | ReactNode) {
  if (textToCopy) {
    return textToCopy;
  }

  if (typeof value !== 'string') {
    return '';
  }

  return value;
}

export function TextWithCopy({
  textToCopy,
  withCopy = true,
  copyIconPosition = CopyIconPosition.RIGHT,
  className,
  color,
  alwaysShowCopyIcon = false,
  kind = TextKind.BODY_REGULAR,
  iconSize = '20px',
  ...props
}: ITextWithCopyProps) {
  const [isCopied, setIsCopied] = useState(false);

  const [, copyToClipboard] = useCopyToClipboard();

  const copyValue = useMemo(
    () => getCopyValue(textToCopy, props.children),
    [textToCopy, props.children],
  );

  const canDoCopy = Boolean(copyValue) && withCopy && !isCopied;

  const onCopy = useCallback(
    (event: MouseEvent) => {
      if (canDoCopy) {
        event.stopPropagation();
        copyToClipboard(copyValue);
        setIsCopied(true);
      }
    },
    [canDoCopy, copyToClipboard, copyValue],
  );

  useEffect(() => {
    isCopied && setTimeout(() => setIsCopied(false), 2400);
  }, [isCopied]);

  return (
    <Wrapper
      className={className}
      canDoCopy={canDoCopy}
      copyIconPosition={copyIconPosition}
      alwaysShowCopyIcon={alwaysShowCopyIcon}
      color={color}
    >
      <ValueText {...props} kind={kind} />
      <CopiedContent
        canDoCopy={canDoCopy}
        isCopied={isCopied}
        kind={kind}
        iconSize={iconSize}
        onCopy={onCopy}
      />
    </Wrapper>
  );
}
