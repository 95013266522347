import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getServerTime } from './useServerTime';
import { useModalController } from '@/ui/hooks/useModalController.ts';
import { getRefreshToken } from '@/auth/functions/getRefreshToken.ts';
import { config } from '@/config';
import { AuthorizedState } from '@/auth/types.ts';
import { getExpireTime } from '@/auth/functions/decodeToken.ts';
import { useDistributedIdle } from '@/auth/useDistributedIdle.ts';

const IDLE_TIMEOUT_SECONDS = Math.min(
  config.security.session.idleStartInS,
  0.5 * config.security.session.tokenLifeTimeInS,
);

const TRACK_INTERVAL_SECONDS = 1;

interface IUseSessionTrackingProps {
  enabled: boolean;
  authorized: AuthorizedState;
  clearTokens: () => void;
  disableAutoRefresh: () => void;
}

export function useSessionTracking({
  enabled,
  authorized,
  clearTokens,
  disableAutoRefresh,
}: IUseSessionTrackingProps) {
  const { isIdle } = useDistributedIdle(IDLE_TIMEOUT_SECONDS * 1000);
  const sessionIdle = useMemo(
    () => authorized === AuthorizedState.AUTHORIZED && isIdle,
    [authorized, isIdle],
  );
  const [processingSessionIdle, setProcessingSessionIdle] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const updateInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const timeoutSessionPopup = useModalController();

  const clearTracking = useCallback(() => {
    if (updateInterval.current) {
      clearInterval(updateInterval.current);
    }
    setProcessingSessionIdle(false);
  }, []);

  const trackSession = useCallback(() => {
    const refreshToken = getRefreshToken();
    const refreshTokenExpireTime = getExpireTime(refreshToken);
    const time = getServerTime();
    const timedelta = refreshTokenExpireTime - time;

    if (timedelta <= 0) {
      setSessionExpired(true);
      clearTracking();
      clearTokens();
    } else {
      setRemainingTime(timedelta);
    }
  }, [clearTracking, clearTokens]);

  useEffect(() => {
    if (enabled && sessionIdle && !processingSessionIdle) {
      setProcessingSessionIdle(true);
      disableAutoRefresh();
      timeoutSessionPopup.open();
      updateInterval.current = setInterval(
        trackSession,
        TRACK_INTERVAL_SECONDS * 1000,
      );
    }
  }, [sessionIdle]);

  return {
    sessionIdle,
    sessionTimeout: IDLE_TIMEOUT_SECONDS,
    sessionExpired,
    remainingTime,
    timeoutSessionPopup,
    clearTracking,
  };
}
