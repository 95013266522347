export const CacheKeys = {
  abilities: 'abilities',
  accountClientUserDetails: 'account_client_user_details',
  actionRequiredPayments: 'action_required_payments',
  publicAccountInfoByNo: 'public_account_info_by_no',
  availableAccountsByPublicNo: 'available_accounts_by_public_no',
  accountMaxTransactionAmount: 'account_max_transaction_amount',
  availableAccounts: 'available_accounts',
  addMoneySubmissions: 'add_money_submissions',
  addMoneySubmissionPreview: 'add_money_submissions_preview',
  addMoneySubmissionsInf: 'add_money_submissions_inf',
  addMoneySubmissionsSenders: 'add_money_submissions_senders',
  addMoneySubmissionDocumentsAggregated:
    'add_money_submissions_documents_aggregated',
  aggregatedClients: 'aggregated_clients',
  agreement: 'agreement',
  aidClientDraftVerifications: 'aid_client_draft_verifications',
  aidClientUserVerifications: 'aid_client_user_verifications',
  aidClientVerifications: 'aid_client_verifications',
  aidVerifications: 'aid_verifications',
  auditLogs: 'audit_logs',
  auditLogsInf: 'audit_logs_inf',
  availableSpecialActivities: 'available_special_activities',
  availableTfaChannels: 'available_tfa_channels',
  averageBalance: 'average_balance',
  auditBankLetterAvailability: 'audit_bank_letter_availability',
  balanceWidget: 'balance_widget',
  bankEntities: 'bank_entities',
  bankLetterAvailability: 'bank_letter_availability',
  accountConfirmationLetterAvailability: 'account_confirmation_letter_availability',
  changeDataRequests: 'change_data_requests',
  clientAccounts: 'client_accounts',
  clientAccountInstructions: 'client_account_instructions',
  clientAgreementSigns: 'client_agreement_signs',
  clientDraftAgreementSigns: 'client_draft_agreement_signs',
  clientDrafts: 'client_drafts',
  clients: 'clients',
  clientUserInvites: 'client_user_invites',
  clientUsers: 'client_users',
  clientUserAccountsDetails: 'client_users_accounts_details',
  clientUsersLimitsStat: 'client_users_limits_stat',
  closableAccounts: 'closable_accounts',
  closableClients: 'closable_clients',
  contacts: 'contacts',
  contactsInf: 'contacts_inf',
  contactsAccountByNo: 'contacts_account_by_no',
  contactsBankPaymentMethodFields: 'contacts_bank_payment_method_fields',
  contactsCountries: 'contacts_countries',
  contactsCurrencies: 'contacts_currencies',
  contactsCurrencyByCountry: 'contacts_currency_by_country',
  contactsModifying: 'contacts_modifying',
  contactsPaymentKinds: 'contacts_payment_kinds',
  contactsPaymentMethods: 'contacts_payment_methods',
  contactsPaymentMethodValidation: 'contacts_payment_method_validation',
  contactsWisePaymentMethodFields: 'contacts_wise_payment_method_fields',
  counters: 'counters',
  countries: 'countries',
  directoryItems: 'directory_items',
  directoryItemsCountries: 'directory_items_countries',
  directoryItemsSalutations: 'directory_items_salutations',
  displayTransactions: 'display_transactions',
  displayTransactionsInf: 'display_transactions_inf',
  documents: 'documents',
  documentsInf: 'documents_inf',
  documentsUserIds: 'documents_user_ids',
  documentsKinds: 'documents_kinds',
  documentsContentTypes: 'documents_content_types',
  documentsCount: 'documents_count',
  documentRelations: 'document_relations',
  drafts: 'drafts',
  draftPreview: 'draft_preview',
  draftReferences: 'drafts_references',
  draftDocumentsAggregated: 'drafts_documents_aggregated',
  draftsInf: 'drafts_inf',
  feesStats: 'fees_stats',
  integrations: 'integrations',
  invoices: 'invoices',
  invoicesInf: 'invoices_inf',
  invoicesSenders: 'invoices_senders',
  labels: 'labels',
  limitsStat: 'limits_stat',
  mostReceivedFromStats: 'most_received_from_stats',
  mostSentToStats: 'most_sent_to_stats',
  notifications: 'notifications',
  notificationsCounters: 'notifications_counters',
  newOtp: 'new_otp',
  onboardingInvite: 'onboarding_invite',
  overallBalance: 'overall_balance',
  paymentMethods: 'payment_methods',
  paymentMethodAccounts: 'payment_method_accounts',
  paymentMethodsCurrencies: 'payment_methods_currencies',
  paymentsByLabelStats: 'payments_by_label_stats',
  pendingTransactions: 'pending_transactions',
  pendingTransactionsInf: 'pending_transactions_inf',
  pinnedDocuments: 'pinned_documents',
  plannedPayments: 'planned_payments',
  previewDeactivation: 'preview_deactivation',
  publicLinks: 'public_links',
  purposesOfTransferStats: 'purposes_of_transfer_stats',
  recentContacts: 'recent_contacts',
  recentContactsInf: 'recent_contacts_inf',
  referralCode: 'referral_code',
  referrals: 'referrals',
  rfi: 'rfi',
  roles: 'roles',
  roleTemplates: 'role_templates',
  searchQueries: 'search_queries',
  settings: 'settings',
  supportRequests: 'support_requests',
  supportingDocumentsRequests: 'supporting_documents_requests',
  tariff: 'tariff',
  tariffGroup: 'tariff_group',
  tariffQuestions: 'tariff_questions',
  topAccountsStats: 'top_accounts_stats',
  transactionsAmount: 'transactions_amount',
  transactionAuditLogs: 'transactions_audit_logs',
  user: 'user',
  userAgreementSigns: 'user_agreement_signs',
  userClientUsers: 'user_client_users',
  userInvites: 'user_invites',
  userSessions: 'user_sessions',
  userSessionsInf: 'user_sessions_inf',
  userWs: 'user_ws',
  webAuthnCredentials: 'webauthn_credentials',
};

export const ServiceCacheKeys = {
  infinity: 'infinity',
  widget: 'widget',
  entityDetails: 'entity-details',
};

// get one
// useSWR([CacheKeys.publicLinks, id], async () => api.publicLinks.getPublicLink({}))

// get all
// useSWR([CacheKeys.publicLinks], async () => api.publicLinks.getPublicLink({}))

//
// export const CacheKeys = {
//   accounts: 'accounts',
//   accountClientUserDetails: 'account_client_user_details',
//   addMoneySubmissions: 'add_money_submissions',
//   addMoneySubmissionsInf: 'add_money_submissions_inf',
//   addMoneySubmissionsSenders: 'add_money_submissions_senders',
//   aidVerifications: 'aid_verifications',
//   aidClientDraftVerifications: 'aid_client_draft_verifications',
//   aidClientVerifications: 'aid_client_verifications',
//   aidClientUserVerifications: 'aid_client_user_verifications',
//   availableSpecialActivities: 'available_special_activities',
//   averageBalance: 'average_balance',
//   bankEntities: 'bank_entities',
//   clientAgreementSigns: 'client_agreement_signs',
//   clientAccounts: 'client_accounts',
//   clientDrafts: 'client_drafts',
//   clientDraftAgreementSigns: 'client_draft_agreement_signs',
//   clientUsers: 'client_users',
//   clients: 'clients',
//   closableClients: 'closable_clients',
//   contacts: 'contacts',
//   contactsModifying: 'contacts_modifying',
//   contactsCountries: 'contacts_countries',
//   contactsPaymentKinds: 'contacts_payment_kinds',
//   contactsCurrencies: 'contacts_currencies',
//   contactsCurrencyByCountry: 'contacts_currency_by_country',
//   contactsPaymentMethods: 'contacts_payment_methods',
//   contactsBankPaymentMethodFields: 'contacts_bank_payment_method_fields',
//   contactsWisePaymentMethodFields: 'contacts_wise_payment_method_fields',
//   contactsAccountByNo: 'contacts_account_by_no',
//   counters: 'counters',
//   countries: 'countries',
//   directoryItems: 'directory_items',
//   displayTransactions: 'display_transactions',
//   displayTransactionsInf: 'display_transactions_inf',
//   drafts: 'drafts',
//   draftsInf: 'drafts_inf',
//   documents: 'documents',
//   documentsInf: 'documents_inf',
//   documentsUserIds: 'documents_user_ids',
//   integrations: 'integrations',
//   invoices: 'invoices',
//   invoicesInf: 'invoices_inf',
//   invoicesSenders: 'invoices_senders',
//   labels: 'labels',
//   notifications: 'notifications',
//   notificationsCounters: 'notifications_counters',
//   onboardingInvite: 'onboarding_invite',
//   paymentMethods: 'payment_methods',
//   pendingTransactions: 'pending_transactions',
//   pendingTransactionsInf: 'pending_transactions_inf',
//   pinnedDocuments: 'pinned_documents',
//   publicLinks: 'public_links',
//   recentContacts: 'recent_contacts',
//   searchQueries: 'search_queries',
//   settings: 'settings',
//   supportingDocumentsRequests: 'supporting_documents_requests',
//   tariffQuestions: 'tariff_questions',
//   tariffGroup: 'tariff_group',
//   tariff: 'tariff',
//   topAccountsStats: 'top_accounts_stats',
//   purposesOfTransferStats: 'purposes_of_transfer_stats',
//   paymentsByLabelStats: 'payments_by_label_stats',
//   mostSentToStats: 'most_sent_to_stats',
//   mostReceivedFromStats: 'most_received_from_stats',
//   feesStats: 'fees_stats',
//   transactionsAmount: 'transactions_amount',
//   user: 'user',
//   userClientUsers: 'user_client_users',
//   userInvites: 'user_invites',
//   userAgreementSigns: 'user_agreement_signs',
//   userWs: 'user_ws',
//   clientUserInvites: 'client_user_invites',
//   roles: 'roles',
//   auditLogs: 'audit_logs',
//   auditLogsInf: 'audit_logs_inf',
//   rfi: 'rfi',
//   userSessions: 'user_sessions',
//   // userSessionsInf: 'user_sessions_inf',
//   referralCode: 'referral_code',
//   referrals: 'referrals',
//   agreement: 'agreement',
//   closableAccounts: 'closable_accounts',
// };
//
// // get one
// // useSWR([CacheKeys.publicLinks, id], async () => api.publicLinks.getPublicLink({}))
//
// // get all
// // useSWR([CacheKeys.publicLinks], async () => api.publicLinks.getPublicLink({}))
